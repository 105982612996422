
import NotificationActiveIcon from "../../assets/icons/notification-active.svg?react";
import NotificationIcon from "../../assets/icons/notification.svg?react";
import TriangleDownIcon from "../../assets/icons/triangle-down.svg?react";
import UserIcon from "../../assets/icons/user.svg?react";
import classNames from "classnames";
import { HTMLAttributes, ReactElement, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import {
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenu as DropdownMenuRoot,
  DropdownMenuTrigger,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from "@repos/digikoo-design-system-react/dropdown-menu";
import i18n from "../../i18n";
import { resolveProductIcon } from "./util";
import { cloneElement } from "react";

export interface ProductDropdownItem {
  productId: string;
  id: string;
  productTitle: string;
  productUrl: string;
}

export type HeaderToolbarProps = HTMLAttributes<HTMLElement> & {
  productId: string;
  productTitle: string;
  productDropdownProducts?: ProductDropdownItem[];
  organisation?: ReactNode;
  rightColumnContent?: ReactNode;
  userDropdownChildren?: ReactNode;
  notificationDropdownChildren?: ReactNode;
  languageKey?: string;
  organisationColor?: string;

};

const HeaderToolbar = ({
  productId,
  productTitle,
  productDropdownProducts = [],
  className,
  children,
  organisation,
  rightColumnContent,
  userDropdownChildren,
  notificationDropdownChildren,
  languageKey,
  organisationColor,
  ...props
}: HeaderToolbarProps): ReactElement => {
  const { t } = useTranslation();

  const ProductIcon = resolveProductIcon(productId);

  const hasActiveNotifications = true;
  const DynamicNotificationIcon = hasActiveNotifications
    ? NotificationActiveIcon
    : NotificationIcon;

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
  };

  return (
    <header
      className={classNames(
        className,
        "dds-border-b dds-border-gray-200 dds-h-20 dds-px-6 dds-sticky dds-z-40 dds-top-0 dds-inset-0 dds-bg-primary-100"
      )}
      {...props}
    >
      <div className="dds-h-full dds-flex dds-gap-x-10 dds-justify-between">
        {/* Left content */}
        <div className="dds-flex dds-items-center dds-gap-x-6 dds-divide-x dds-divide-gray-200">
          {/* Product dropdown */}
          <DropdownMenuRoot>
            <DropdownMenuTrigger asChild>
              <button
                className="dds-flex dds-items-center dds-gap-x-3 dds-group dds-outline-none"
                disabled={!productDropdownProducts.length}
              >
                {/* Icon */}
                <span className="dds-text-primary-600 dds-w-10 dds-h-10 dds-rounded-lg dds-border dds-border-gray-200 group-focus-visible:dds-border-gray-300 dds-flex dds-items-center dds-justify-center">
                  {ProductIcon}
                </span>

                <span className="dds-flex dds-gap-x-1 dds-items-center">
                  {/* Title */}
                  <span className="dds-text-16 dds-leading-tight dds-font-semibold">
                    {productTitle}
                  </span>

                  {/* Indicator */}
                  {!!productDropdownProducts.length && (
                    <span
                      className={classNames(
                        "dds-shrink-0 group-data-[state=open]:-dds-scale-y-100 dds-transition dds-duration-300"
                      )}
                    >
                      <TriangleDownIcon className="dds-w-1.5 dds-h-auto" />
                    </span>
                  )}
                </span>
              </button>
            </DropdownMenuTrigger>

            {!!productDropdownProducts.length && (
              <DropdownMenuContent
                side="bottom"
                align="start"
                sideOffset={16}
                className="dds-z-50 w-max"
              >
                <DropdownMenuGroup className="dds-space-y-2">
                  {productDropdownProducts.map((product) => {
                    const ProductIcon = resolveProductIcon(product.productId);

                    return (
                      <DropdownMenuItem
                        key={product.productId}
                        condensed
                        onClick={() => {
                          if (product.productId === "launchpad") {
                            location.href = "/launchpad";
                          } else {
                            if (product.productUrl)
                              location.href = product.productUrl;
                          }
                        }}
                      >
                        <div className="dds-flex dds-items-center dds-gap-x-3">
                          {/* Icon */}
                          <span className="dds-text-primary-600 dds-w-10 dds-h-10 dds-rounded-lg dds-border dds-border-gray-200 group-focus-visible:dds-border-gray-300 dds-flex dds-items-center dds-justify-center"
                          style={{ color: organisationColor || "#00875A" }}
                          >
                           {ProductIcon && (
                              cloneElement(ProductIcon, {
                             //   fill: "currentColor",
                                stroke: "currentColor",
                              })
                              )}
                          </span>

                          {/* Title */}
                          <span className="dds-text-16 dds-leading-tight dds-tracking-normal dds-font-semibold">
                            {product.productTitle}
                          </span>
                        </div>
                      </DropdownMenuItem>
                    );
                  })}
                </DropdownMenuGroup>
              </DropdownMenuContent>
            )}
          </DropdownMenuRoot>

          {/* Organisation logo */}
          {organisation && (
            <div className="dds-h-6 dds-flex dds-items-center dds-pl-6">
              {organisation}
            </div>
          )}
        </div>
        {/* Middle content */}
        {!!children && (
          <div className="dds-absolute dds-left-1/2 -dds-translate-x-1/2 dds-inset-y-0 dds-flex dds-items-center">
            <div>{children}</div>
          </div>
        )}

        {/* Right content */}
        {(!!rightColumnContent ||
          !!notificationDropdownChildren ||
          !!userDropdownChildren) && (
            <div className="dds-h-full dds-shrink-0">
              {/* Optional custom content */}
              {rightColumnContent ?? (
                // Default: Dropdowns
                <div className="dds-h-full dds-flex dds-gap-x-6 dds-items-center">
                  {/* Notifications dropdown */}
                  {notificationDropdownChildren && (
                    <DropdownMenuRoot>
                      <DropdownMenuTrigger asChild>
                        <button className="dds-h-6 dds-w-6 focus-visible:dds-bg-gray-100 focus-visible:dds-shadow-[0_0_0_5px_#ECEBEB] dds-outline-none dds-rounded-full">
                          <DynamicNotificationIcon className="dds-h-full dds-w-full" />
                          <span className="dds-sr-only">
                            {t("topbar.open-notifications-dropdown")}
                          </span>
                        </button>
                      </DropdownMenuTrigger>

                      <DropdownMenuContent
                        side="bottom"
                        align="end"
                        sideOffset={16}
                        className={`dds-z-50 ${dropwdownClassName}`}
                      >
                        {notificationDropdownChildren}
                      </DropdownMenuContent>
                    </DropdownMenuRoot>
                  )}
                  {/* Language selector */}
                  <DropdownMenuRoot>
                    <DropdownMenuTrigger asChild>
                      <button className="dds-h-6 dds-w-6 focus-visible:dds-bg-gray-100 focus-visible:dds-shadow-[0_0_0_5px_#ECEBEB] dds-outline-none dds-rounded-full">
                        <span className="dds-bolder">
                          {i18n?.resolvedLanguage === "en" ? "EN" : "DE"}
                        </span>
                      </button>
                    </DropdownMenuTrigger>

                    <DropdownMenuContent
                      side="bottom"
                      align="end"
                      sideOffset={16}
                      className="dds-z-50"
                    >
                      <DropdownMenuRadioGroup
                        value={i18n?.resolvedLanguage}
                        className="dds-space-y-2"
                      >
                        <DropdownMenuRadioItem
                          value="de"
                          onClick={() => changeLanguage("de")}
                        >
                          Deutsch
                        </DropdownMenuRadioItem>
                        <DropdownMenuRadioItem
                          value="en"
                          onClick={() => changeLanguage("en")}
                        >
                          English
                        </DropdownMenuRadioItem>
                      </DropdownMenuRadioGroup>
                    </DropdownMenuContent>
                  </DropdownMenuRoot>

                  {/* User dropdown */}
                  {userDropdownChildren && (
                    <DropdownMenuRoot>
                      <DropdownMenuTrigger asChild>
                        <button className="dds-h-6 dds-w-6 focus-visible:dds-bg-gray-100 focus-visible:dds-shadow-[0_0_0_5px_#ECEBEB] dds-outline-none dds-rounded-full">
                          <UserIcon className="dds-h-full dds-w-full" />
                          <span className="dds-sr-only">
                            {t("topbar.open-user-dropdown")}
                          </span>
                        </button>
                      </DropdownMenuTrigger>

                      <DropdownMenuContent
                        side="bottom"
                        align="end"
                        sideOffset={16}
                        className={`dds-z-50 ${dropwdownClassName}`}
                      >
                        {userDropdownChildren}
                      </DropdownMenuContent>
                    </DropdownMenuRoot>
                  )}
                </div>
              )}
            </div>
          )}
      </div>
    </header>
  );
};

export default HeaderToolbar;

const dropwdownClassName = "!w-max min-w-[220px]";