import {
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from "@repos/digikoo-design-system-react/dropdown-menu";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { signOut, useSession } from "next-auth/react";
import { navigate } from "vike/client/router";
import HeaderToolbar, { ProductDropdownItem } from "../toolbars/headerToolbar";
import UserService from "../../services/api/services/userService";

export type HeaderProps = {
  activeProductId?: string;
  activeProductTitle?: string;
};

export function Header({ activeProductId, activeProductTitle }: HeaderProps) {
  const { t } = useTranslation();
  const { data: session } = useSession();

  const user = {
    email: session?.user?.email,
    organisation: session?.user?.org,
  };

  const userService = useMemo(() => {
    return new UserService(session?.accessToken);
  }, [session?.accessToken]);

  const [availableProducts, setAvailableProducts] = useState<
    ProductDropdownItem[]
  >([]);

  const fetchUserProducts = useCallback(async () => {
    try {
      const apps = await userService.getApplications(session?.user?.id ?? "");
      if (apps?.data?.length) {
        const productsMapping = apps.data.map((d) => {
          return {
            productId: d.name,
            id: d.id,
            productTitle: d.name,
            productUrl: d.baseUrl,
          } as ProductDropdownItem;
        });

        setAvailableProducts(productsMapping);
      }
    } catch (error) {
      return [];
    }
  }, [userService, session?.user?.id]);

  useEffect(() => {
    fetchUserProducts();
  }, [fetchUserProducts]);

  //TODO:implement user info
  const dropdownChildren = useMemo(() => {
    return (
      <>
        <h3 className="headline-4 mb-4 mx-4 mt-4">
          {t("topbar.user-dropdown.headline")}
        </h3>

        <DropdownMenuGroup>
          {!!user.email && (
            <DropdownMenuItem hasInteraction={false} disabled>
              <dl>
                <dt className="text-13 leading-tight tracking-wider font-semibold text-gray-400 uppercase mb-1">
                  {t("topbar.user-dropdown.email")}
                </dt>
                <dd className="text-13 leading-tight tracking-wider font-bold">
                  {user.email}
                </dd>
              </dl>
            </DropdownMenuItem>
          )}

          {!!user.organisation && (
            <DropdownMenuItem hasInteraction={false} disabled>
              <dl>
                <dt className="text-13 leading-tight tracking-wider font-semibold text-gray-400 uppercase mb-1">
                  {t("topbar.user-dropdown.organisation")}
                </dt>
                <dd className="text-13 leading-tight tracking-wider font-bold">
                  {user.organisation.name}
                </dd>
              </dl>
            </DropdownMenuItem>
          )}
        </DropdownMenuGroup>

        <DropdownMenuSeparator />

        <DropdownMenuGroup className="space-y-2">
          <DropdownMenuItem onClick={() => navigate("/account")}>
            {t("topbar.user-dropdown.account-settings")}
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() =>
              signOut({
                callbackUrl: "/",
              })
            }
          >
            {t("topbar.user-dropdown.logout")}
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </>
    );
  }, [t]);

  return (
    <>
      <HeaderToolbar
        productId={activeProductId ?? "configurator"}
        productTitle={activeProductTitle ?? "configurator"}
        productDropdownProducts={availableProducts}
        organisation={
          user.organisation?.logo ? (
            <p>
              {user.organisation.logo && <> <img
                className="h-5 w-auto"
                src={user.organisation.logo}
                alt={user.organisation.name} />

                <span className="sr-only">{user.organisation.name}</span>
              </>
              }
            </p>
          ) : undefined
        }
        userDropdownChildren={dropdownChildren}
        organisationColor={user.organisation?.color} 

      />
    </>
  );
}
